<template>
  <div
    :class="isDarkMode ? 'dark justify-end dark:bg-[#232323]' : 'justify-start bg-white'"
    class="border border-cs-light-grey dark:border-cs-medium-grey rounded-[50px] p-1 cursor-pointer w-[60px] flex"
    @click="$toggleDarkTheme"
  >
    <Transition name="slide-fade">
      <IconMoon v-if="isDarkMode" />
      <IconSun v-else />
    </Transition>
  </div>
</template>

<script lang="ts" setup>
import IconSun from 'assets/icons/landing/icon-sun.svg'
import IconMoon from 'assets/icons/landing/icon-moon.svg'
import {useIsDarkMode} from '~/composables/common'

interface ComponentProps {}

defineProps<ComponentProps>()

const {$toggleDarkTheme} = useNuxtApp()

const isDarkMode = useIsDarkMode()
</script>

<style scoped>
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from {
  transform: translateX(20px);
}

div.dark .slide-fade-enter-from {
  transform: translateX(-20px);
}
</style>
